<template>
  <v-expansion-panels v-model="expanded" v-if="groups.length > 0">
    <v-expansion-panel
      v-for="(group, index) in groups"
      :key="index"
      class="mb-4"
    >
      <v-expansion-panel-header
        hide-actions
        v-slot="{ open }"
        :style="headerStyles(index)"
        :class="headerClass(index)"
      >
        <v-row align="center">
          <v-col class="shrink">
            <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
            <v-icon v-else>mdi-plus-thick</v-icon>
          </v-col>
          <v-col :class="(open ? 'white--text' : '') + ' font-weight-medium'">
            {{ group.name }}
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content :key="group.id" class="pa-4">
        <v-data-table
          :headers="headers"
          :items="group.courses"
          disable-filtering
          disable-pagination
          hide-default-footer
          checkbox-color="msaBlue"
          item-key="id"
          class="private-document-list"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="msaBlue"
              class="white--text"
              @click="$emit('select', item)"
              >Select</v-btn
            >
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: 'CourseSelectorGroups',
  props: {
    groups: {
      type: Array,
    },
  },
  data() {
    return {
      expanded: null,
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightBg',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '120px',
          class: 'lightBg',
        },
      ],
    };
  },
  computed: {
    headerStyles() {
      return (index) =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
    headerClass() {
      return (index) => (index == this.expanded ? 'msaBlue white--text' : '');
    },
  },
};
</script>

<style></style>
