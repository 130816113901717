<template>
  <v-expansion-panels v-model="expanded" v-if="groups.length > 0">
    <v-expansion-panel
      v-for="(group, index) in groups"
      :key="index"
      class="mb-4"
    >
      <v-expansion-panel-header
        hide-actions
        v-slot="{ open }"
        :style="headerStyles(index)"
        :class="headerClass(index)"
      >
        <v-row align="center">
          <v-col class="shrink">
            <v-icon v-if="open" color="white">mdi-minus-thick</v-icon>
            <v-icon v-else>mdi-plus-thick</v-icon>
          </v-col>
          <v-col :class="(open ? 'white--text' : '') + ' font-weight-medium'">
            {{ group.name }}
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content :key="group.id" class="pa-4">
        <DocumentSelectorGroups
          class="pb-4"
          :groups="group.groups"
          :key="group.id"
          @select="$emit('select', $event)"
        />
        <DocumentSelectorList
          :documents="group.documents"
          @select="$emit('select', $event)"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import DocumentSelectorList from '@/components/TrainingTasks/DocumentSelectorList.vue';
import DocumentSelectorGroups from '@/components/TrainingTasks/DocumentSelectorGroups.vue';

export default {
  name: 'DocumentSelectorGroups',
  components: {
    DocumentSelectorList,
    DocumentSelectorGroups,
  },
  props: {
    groups: {
      type: Array,
    },
  },
  data() {
    return {
      expanded: null,
    };
  },
  computed: {
    headerStyles() {
      return (index) =>
        index == this.expanded
          ? {}
          : {
              backgroundImage:
                'linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%)',
            };
    },
    headerClass() {
      return (index) => (index == this.expanded ? 'msaBlue white--text' : '');
    },
  },
};
</script>

<style></style>
