<template>
  <v-card v-if="documents.length > 0">
    <v-data-table
      :headers="headers"
      :items="documents"
      disable-filtering
      disable-pagination
      hide-default-footer
      checkbox-color="msaBlue"
      item-key="id"
      class="private-document-list"
    >
      <template v-slot:[`item.name`]="{ item }">
        <FileTypeIcon :filename="item.originalFilename" />
        {{ item.name }}
        <span v-if="item.isExpired" class="red--text text-caption font-italic">
          (Expired)
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.expiryDate`]="{ item }">
        {{ item.expiryDate | filterAsLocalDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="msaBlue"
          class="white--text"
          @click="$emit('select', item)"
          >Select</v-btn
        >
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import FileTypeIcon from '@/components/FileTypeIcon.vue';

export default {
  name: 'DocumentSelectorList',
  components: { FileTypeIcon },
  props: {
    documents: {
      type: Array,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'lightBg',
        },
        {
          text: 'Created Date',
          value: 'createdAt',
          width: '130px',
          class: 'lightBg',
        },
        {
          text: 'Expiry Date',
          value: 'expiryDate',
          width: '130px',
          class: 'lightBg',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '120px',
          class: 'lightBg',
        },
      ],
    };
  },
};
</script>

<style>
.private-document-list .v-data-table-header th {
  background-color: #ebf1fc !important;
  border-bottom: none !important;
}
</style>
