<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <v-card>
        <v-card-title class="msaBlue white--text"> Select Course </v-card-title>
        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="6">
                <SearchBar
                  ref="searchBar"
                  @search="onSearch"
                  searchLabel="Search by course name..."
                  :disabled="isLoading"
                />
              </v-col>
            </v-row>
            <v-row v-if="isLoading">
              <v-col>
                <v-progress-linear
                  indeterminate
                  color="msaBlue"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <CourseSelectorGroups
                  v-if="groups.length"
                  :isSearching="isSearching"
                  :groups="groups"
                  :key="isLoading"
                  @select="onSelect"
                />
                <div v-else>
                  <span v-if="!isLoading"
                    >There are no documents that match your search</span
                  >.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="msaBlue white--text" @click="close">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn color="msaBlue" dark @click="show">{{ buttonLabel }}</v-btn>
  </div>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import CourseSelectorGroups from '@/components/TrainingTasks/CourseSelectorGroups.vue';

export default {
  components: { SearchBar, CourseSelectorGroups },
  props: {
    buttonLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      groups: [],
      dialog: false,
      search: '',
      isLoading: false,
    };
  },
  watch: {
    dialog(nv) {
      if (nv) {
        this.getCourses();
      } else {
        this.clear();
      }
    },
  },
  computed: {
    isSearching() {
      if (!this.search) {
        return false;
      }
      return this.search.trim().length > 0;
    },
  },
  methods: {
    onSelect(data) {
      this.dialog = false;
      this.$emit('select', data);
    },
    onSearch(newSearch) {
      this.search = newSearch;
      this.getCourses();
    },
    getCourses() {
      this.isLoading = true;
      const params = {
        courseName: this.search,
      };
      this.$axios
        .post('get-courses-for-add?format=json', params)
        .then((response) => {
          this.isLoading = false;
          this.groups = response.data;
        });
    },
    show() {
      this.dialog = true;
    },
    clear() {
      this.groups = [];
      this.search = '';
      this.$refs.searchBar.setSearch('');
    },
    close() {
      this.clear();
      this.dialog = false;
    },
  },
};
</script>

<style></style>
